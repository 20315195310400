<template>
  <v-card flat class="px-5 ml-sm-3 mt-10">
    <v-alert
      text
      outlined
      type="info"
      v-if="brandUserRolesAdminAccessErrorMessage"
      class="mt-4 col-12 text-center"
    >
      {{ brandUserRolesAdminAccessErrorMessage }}
    </v-alert>

    <v-alert
      dismissible
      text
      outlined
      type="error"
      v-if="brandUserRolesErrorMessage"
      class="mt-4 col-12 text-center"
    >
      {{ brandUserRolesErrorMessage }}
    </v-alert>
    <v-card-title>{{ $t('ManageBrandUserSecurityAccess') }}</v-card-title>
    <v-card-text>
      <v-text-field
        v-model="inviteEmailUser"
        append-icon="fa-plus-circle"
        v-if="$appConfig.brand && $appConfig.brand.features.userQuota"
        :disabled="brandRoles.length >= $appConfig.brand.features.userQuota"
        label="E-mail"
        type="email"
        ref="inviteEmailBrandUserForm"
        :rules="[
          (value) => isEmail(value) || $t('PleaseEnterAValidEmail'),
          (value) =>
            !isBrandUserAlreadyExist(value) || $t('EmailDuplicatedInList'),
        ]"
        @keyup.enter="inviteBrandUser"
        outlined
        @click:append="inviteBrandUser"
      >
      </v-text-field>
      <v-alert
        outlined
        type="info"
        text
        v-if="
          $appConfig.brand.features &&
            brandRoles.length >= $appConfig.brand.features.userQuota
        "
      >
        {{ $t('userQuotaReached') }}
      </v-alert>
      <v-divider></v-divider>
    </v-card-text>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="searchBrandRoles"
        append-icon="fas fa-search"
        :label="`${$t('Search')}`"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headersBrandRoles"
        :items="brandRoles"
        :custom-filter="customFilter"
        :search="searchBrandRoles"
        :footer-props="{
          itemsPerPageOptions: [15, 50, 100, -1],
        }"
      >
        <template v-slot:[`item._id`]="{ item }">
          <router-link :to="{name: 'User', params: { brandUUID: `${$appConfig.currentBrand}`, userUUID: `${item._id}`}}">
          </router-link>

          <router-link
            :to="{name: 'User', params: { brandUUID: `${$appConfig.currentBrand}`, userUUID: `${item._id}`}}"
            class="text-decoration-none"
          >
            <span v-if="item.firstname !== ' ' || item.lastname !== ' '">
              {{ item.firstname }} - <strong>{{ item.lastname }}</strong></span>
            <span v-else>
              <v-icon x-small>fas fa-pen</v-icon> {{ $t('completeProfile') }}
            </span>
          </router-link>
          <span> <br>{{ item.email }}</span>
        </template>
        <template v-slot:[`item.admin`]="{ item }">
          <v-switch
            v-model="item.admin"
            color="success"
            inset
            @change="roleChange"
          ></v-switch>
        </template>
        <template v-slot:[`item.access`]="{ item }">
          <v-switch
            v-model="item.access"
            color="success"
            inset
            @change="roleChange"
          ></v-switch>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="error"
        class="text-none mr-2"
        @click="cancelBrandAccessSecurity"
        :disabled="!roleModified"
      >
        <v-icon small class="mr-1">
          fa fa-ban
        </v-icon> {{ $t('Cancel') }}
      </v-btn>
      <v-btn
        color="success"
        class="text-none"
        @click="submitBrandAccessSecurity"
        :disabled="!roleModified"
      >
        <v-icon small class="mr-1">
          fa fa-check
        </v-icon> {{ $t('Submit') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { EventBus, ApiErrorParser, CustomFilterForSearchDatatable } from '@cloudmanufacturingtechnologies/portal-components';
import isEmail from 'validator/lib/isEmail';

const i18nData = require('./pageAdministrationUsers.i18n.json');

export default {
  name: 'PageAdministrationUsers',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      brandUserRolesErrorMessage: null,
      disabled: true,
      brandRolesData: null,
      brandUserRoles: null,
      brandRoles: [],
      roleModified: false,
      headersBrandRoles: [],
      searchBrandRoles: '',
      brandUserRolesAdminAccessErrorMessage: '',
      inviteEmailUser: '',
      isEmail,
    };
  },
  created() {
    if (!this.$appConfig.brand) {
      EventBus.$emit('reloadBrand');
    }
    /**
     * GET BRAND USER ROLES
     */
    this.getBrandUserRoles();
    /**
     * GET BRAND ROLES
     */
    this.getBrandRoles();
  },
  mounted() {
    this.headersBrandRoles = [
      { text: this.$t('user'), value: '_id' },
      { text: this.$t('admin'), value: 'admin' },
      { text: this.$t('access'), value: 'access' },
    ];
  },
  methods: {
    customFilter(items, search, filter) {
      this.brandRoles.forEach(user => {
        if(items === user._id) {
          items = (user._id + ' ' + user.email + ' ' + user.firstname + ' ' + user.lastname + ' ' + user.firstname).toLowerCase();
          items = items.replace(/\s/g, '');
          search = search.replace(/\s/g, '');
        }
      });
      return CustomFilterForSearchDatatable.customFilter(items, search, this);
    },
    /**
     * GET BRAND USER ROLES
     */
    getBrandUserRoles() {
      this.$apiInstance
        .getBrandUserRoles(
          this.$appConfig.currentBrand,
          this.$appConfig.user._id
        )
        .then(
          (brandUserRolesData) => {
            this.brandUserRoles = brandUserRolesData;
            if (this.brandUserRoles.includes('admin')) {
              this.disabled = false;
            } else {
              this.brandUserRolesAdminAccessErrorMessage = this.$t(
                'needToBeAdmin'
              );
            }
          },
          (error) => {
            this.brandUserRolesErrorMessage = ApiErrorParser.parser(error);
          }
        );
    },
    /**
     * GET BRAND ROLES
     */
    getBrandRoles() {
      this.$apiInstance.getBrandRoles(this.$appConfig.currentBrand).then(
        (brandRolesData) => {
          this.brandRolesData = brandRolesData;
          this.parseBrandRoles(this.brandRolesData);
        },
        (error) => {
          /**
           * ERROR GET BRAND ROLES
           */
          this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
        }
      );
    },
    /**
     * PARSE BRAND ROLES
     */
    parseBrandRoles(brandRolesData) {
      const brandRolesDataCopy = JSON.parse(JSON.stringify(brandRolesData));
      const brandRolesArray = [];
      for (const roleUserMapping of brandRolesDataCopy.access) {
        if (!(roleUserMapping._id in brandRolesArray)) {
          brandRolesArray[roleUserMapping._id] = roleUserMapping;
        }
        brandRolesArray[roleUserMapping._id].access = true;
      }
      for (const roleUserMapping of brandRolesDataCopy.admin) {
        if (!(roleUserMapping._id in brandRolesArray)) {
          brandRolesArray[roleUserMapping._id] = roleUserMapping;
        }
        brandRolesArray[roleUserMapping._id].admin = true;
      }
      const brandRoles = [];
      for (const userId in brandRolesArray) {
        if (!brandRolesArray[userId].access) {
          brandRolesArray[userId].access = false;
        }
        if (!brandRolesArray[userId].admin) {
          brandRolesArray[userId].admin = false;
        }
        brandRoles.push(brandRolesArray[userId]);
      }
      this.brandRoles = brandRoles;
    },
    /**
     * CHECK IF A BRAND USER ALREADY EXIST
     */
    isBrandUserAlreadyExist(email) {
      let found = false;
      this.brandRoles.forEach((user) => {
        if (user.email.toLowerCase() === email.toLowerCase()) {
          found = true;
          return found;
        }
      });
      return found;
    },
    /**
     * INVITE BRAND USER
     */
    inviteBrandUser() {
      if (this.$refs.inviteEmailBrandUserForm.validate(true)) {
        const inviteBrandUserBody = new this.$BcmModel.InviteBrandUserBody(
          this.inviteEmailUser
        );
        this.$apiInstance
          .inviteBrandUser(this.$route.params.brandUUID, inviteBrandUserBody)
          .then(
            (data) => {
              this.inviteEmailUser = '';
              this.$notification.notify('SUCCESS',this.$t('HasBeenSuccessfullyAdded', { email: data.email }));
            },
            (error) => {
              EventBus.$emit('reloadBrand');
              /**
               * ERROR INVITE BRAND USER
               */
              this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
            }
          )
          .finally(() => {
            this.getBrandRoles();
          });
      }
    },
    /**
     * CHECK IF ROLE CHANGE
     */
    roleChange() {
      this.roleModified = true;
    },
    /**
     * SUBMIT BRAND ACCESS SECURITY
     */
    submitBrandAccessSecurity() {
      const rolesUserMapping = new this.$BcmModel.Roles();
      for (const role in rolesUserMapping) {
        rolesUserMapping[role] = [];
      }
      for (const userMapping of this.brandRoles) {
        if (userMapping.access) {
          rolesUserMapping.access.push(userMapping._id);
        }
        if (userMapping.admin) {
          rolesUserMapping.admin.push(userMapping._id);
        }
      }
      this.$apiInstance
        .modifyBrandRoles(this.$route.params.brandUUID, rolesUserMapping)
        .then(
          (data) => {
            this.brandRolesData = data;
            this.parseBrandRoles(this.brandRolesData);
            this.roleModified = false;
            /**
             * Component BeelseNotifications used
             */
            this.$notification.notify('SUCCESS',this.$t('RoleSuccessfullyModified'));
          },
          (error) => {
            /**
             * ERROR SAVE BRAND ROLES
             */
            /**
             * Component BeelseNotifications used
             */
            this.$notification.notify('DANGER', this.$t(ApiErrorParser.parse(error)));
            this.cancelBrandAccessSecurity();
          }
        );
    },
    /**
     * CANCEL BRAND ACCESS SECURITY
     */
    cancelBrandAccessSecurity() {
      this.parseBrandRoles(this.brandRolesData);
      this.roleModified = false;
    },
  },
};
</script>
